<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="rounded-t mb-0 px-6 py-6">
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Sign in with credentials</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Email</label>
                <input type="email" v-model="username" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Email"/>
              </div>
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Password</label>
                <input type="password" v-model="password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Password"/>
              </div>
              <div class="text-center mt-6">
                <button class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="button" @click.prevent="login">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import auth from '@/json/auth.json';

export default {
  data() {
    return {
      username: "",
      password: ""
    }
  },
  mounted() {
    // console.log(this.$router.params.id);
  },
  methods: {
    login() {
      // console.log(this.username);
      // console.log(auth);
      auth.map((user) => {
        // console.log(user.id);
        if ((this.username === user.email) && (this.password === user.password)) {
          // console.log("Success!");
          // this.$router.push({ path: '/admin/dashboard/' + user.id, params: { id: user.id } })
          // this.$router.push({ path: '/install/step-1' });
          localStorage.setItem('projid', user.projid);
          this.$router.push({ path: '/project/' + user.id, params: { id: user.id } });
        }
        else {
          // console.error("Error!");
        }
        // if (this.username != user.email) {
        //   console.log("Wrong email");
        // }
        // if (this.password != user.password) {
        //   console.log("Wrong password");
        // }
      })
    }
  }
};
</script>
