<template>
  <div class="w-screen h-screen flex justify-center items-center">
    
    <section class="header items-center flex flex-col lg:flex-row h-screen max-h-860-px">
      <div class="items-center flex flex-wrap justify-end">
        <div class="w-full xl:w-8/12 px-4">
          <div class="pt-32 sm:pt-0 flex flex-col">
            <h2 class="font-semibold text-4xl text-blueGray-600">Installazione (1/3)</h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">Clicca su "Installa applicazione" e verrà installata tutta l'infrastruttura software. Al termine, sarà possibile passare al prossimo step.</p>
            <div class="mt-12 flex items-center">
              <a id="get-started" class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150" href="#" @click.prevent="go">Installa applicazione<i class="fas fa-shapes ml-2"></i></a>
              <span class="ml-2 font-bold text-orange-500 mb-1 hidden" id="counter">({{ i }}%)</span>
              <!-- <router-link to="/admin/dashboard" class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">Installa applicazione <i class="fas fa-tasks ml-2"></i></router-link> -->
            </div>
          </div>
          <div class="mt-10 rounded shadow overflow-hidden bg-blueGray-200 hidden" id="myProgress">
            <div class="bg-orange-500" id="myBar"></div>
          </div>
          <!-- <router-link id="get-done" class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 hidden mt-8 max-w-fit" to="/install/step-2">Prosegui <i class="fas fa-arrow-right ml-2"></i></router-link> -->
          <a id="get-done" class="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 hidden mt-8 max-w-fit" href="#" @click.prevent="done">Prosegui <i class="fas fa-arrow-right ml-2"></i></a>
        </div>
      </div>
      <img class="sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px hidden md:flex" :src="patternVue" alt="..."/>
    </section>
    
  </div>
</template>

<script>

import "@fortawesome/fontawesome-free/css/all.min.css";
import patternVue from "@/assets/img/pattern_vue.png";

export default {
  name: 'Step 1',
  data() {
    return {
      patternVue,
      i: 0,
    };
  },
  mounted() {
    // console.log(this.$route.params.id);
  },
  methods: {
    go() {
      // var progress = document.getElementById("myProgress");
      var counter = document.getElementById("counter");
      var getstarted = document.getElementById("get-started");
      var getdone = document.getElementById("get-done");
      // progress.style.display = "flex";
      counter.style.display = "flex";
      getstarted.classList.add('disabled');
      var elem = document.getElementById("myBar");
      var count = setInterval(() => {
        // this.i++;
        if (this.i >= 100) {
          clearInterval(count);
          this.i = 100;
          getdone.style.display = "block";
        } else {
          this.i++;
          elem.style.width = this.i + "%";
        }
        // console.log(this.i);
      }, 300);
    },
    done() {
      // console.log("Done");
      // console.log("/project/" + this.$route.params.id + "/install/step-2");
      this.$router.push({ path: '/project/' + this.$route.params.id + '/install/step-2' });
    }
  },
  components: {
    
  },
};
</script>

<style>
/* #myProgress {
  width: 100%;
  background-color: grey;
} */

.disabled {
  pointer-events: none;
  opacity: .4;
}

#get-done {
  max-width: fit-content;
}

#myBar {
  width: 1%;
  height: 12px;
  /* background-color: green; */
}
</style>
